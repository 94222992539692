export const fontWidthH4 = {
  0: 0,
  1: 7,
  2: 7,
  3: 7,
  4: 7,
  5: 7,
  6: 7,
  7: 7,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
  13: 0,
  14: 7,
  15: 7,
  16: 7,
  17: 7,
  18: 7,
  19: 7,
  20: 7,
  21: 7,
  22: 7,
  23: 7,
  24: 7,
  25: 7,
  26: 7,
  27: 7,
  28: 7,
  29: 0,
  30: 7,
  31: 7,
  32: 0,
  33: 7,
  34: 11,
  35: 10,
  36: 10,
  37: 20,
  38: 17,
  39: 6,
  40: 7,
  41: 7,
  42: 10,
  43: 11,
  44: 5,
  45: 7,
  46: 5,
  47: 6,
  48: 10,
  49: 10,
  50: 10,
  51: 10,
  52: 10,
  53: 10,
  54: 10,
  55: 10,
  56: 10,
  57: 10,
  58: 7,
  59: 7,
  60: 11,
  61: 11,
  62: 11,
  63: 10,
  64: 19,
  65: 14,
  66: 13,
  67: 14,
  68: 14,
  69: 13,
  70: 12,
  71: 16,
  72: 16,
  73: 8,
  74: 10,
  75: 16,
  76: 13,
  77: 19,
  78: 14,
  79: 16,
  80: 12,
  81: 16,
  82: 14,
  83: 11,
  84: 13,
  85: 14,
  86: 14,
  87: 20,
  88: 14,
  89: 14,
  90: 13,
  91: 7,
  92: 6,
  93: 7,
  94: 12,
  95: 10,
  96: 7,
  97: 10,
  98: 11,
  99: 9,
  100: 11,
  101: 9,
  102: 7,
  103: 10,
  104: 11,
  105: 6,
  106: 7,
  107: 11,
  108: 6,
  109: 17,
  110: 11,
  111: 10,
  112: 11,
  113: 11,
  114: 9,
  115: 8,
  116: 7,
  117: 11,
  118: 10,
  119: 14,
  120: 10,
  121: 10,
  122: 9,
  123: 8,
  124: 4,
  125: 8,
  126: 10,
  127: 10,
  128: 12,
  129: 20,
  130: 20,
  131: 20,
  132: 20,
  133: 20,
  134: 20,
  135: 20,
  136: 20,
  137: 20,
  138: 20,
  139: 20,
  140: 20,
  141: 20,
  142: 20,
  143: 20,
  144: 20,
  145: 20,
  146: 20,
  147: 20,
  148: 20,
  149: 20,
  150: 20,
  151: 20,
  152: 20,
  153: 20,
  154: 20,
  155: 20,
  156: 20,
  157: 20,
  158: 20,
  159: 20,
  160: 5,
  161: 7,
  162: 10,
  163: 10,
  164: 10,
  165: 10,
  166: 4,
  167: 10,
  168: 7,
  169: 15,
  170: 6,
  171: 10,
  172: 11,
  173: 0,
  174: 15,
  175: 7,
  176: 8,
  177: 11,
  178: 6,
  179: 6,
  180: 7,
  181: 12,
  182: 11,
  183: 5,
  184: 7,
  185: 6,
  186: 7,
  187: 10,
  188: 15,
  189: 15,
  190: 15,
  191: 10,
  192: 14,
  193: 14,
  194: 14,
  195: 14,
  196: 14,
  197: 14,
  198: 20,
  199: 14,
  200: 13,
  201: 13,
  202: 13,
  203: 13,
  204: 8,
  205: 8,
  206: 8,
  207: 8,
  208: 14,
  209: 14,
  210: 16,
  211: 16,
  212: 16,
  213: 16,
  214: 16,
  215: 11,
  216: 16,
  217: 14,
  218: 14,
  219: 14,
  220: 14,
  221: 14,
  222: 12,
  223: 11,
  224: 10,
  225: 10,
  226: 10,
  227: 10,
  228: 10,
  229: 10,
  230: 14,
  231: 9,
  232: 9,
  233: 9,
  234: 9,
  235: 9,
  236: 6,
  237: 6,
  238: 6,
  239: 6,
  240: 10,
  241: 11,
  242: 10,
  243: 10,
  244: 10,
  245: 10,
  246: 10,
  247: 11,
  248: 10,
  249: 11,
  250: 11,
  251: 11,
  252: 11,
  253: 10,
  254: 11,
  255: 10,
  256: 14,
  257: 10,
  258: 14,
  259: 10,
  260: 14,
  261: 10,
  262: 14,
  263: 9,
  264: 14,
  265: 9,
  266: 14,
  267: 9,
  268: 14,
  269: 9,
  270: 14,
  271: 14,
  272: 14,
  273: 11,
  274: 13,
  275: 9,
  276: 13,
  277: 9,
  278: 13,
  279: 9,
  280: 13,
  281: 9,
  282: 13,
  283: 9,
  284: 16,
  285: 10,
  286: 16,
  287: 10,
  288: 16,
  289: 10,
  290: 16,
  291: 10,
  292: 16,
  293: 11,
  294: 16,
  295: 11,
  296: 8,
  297: 6,
  298: 8,
  299: 6,
  300: 8,
  301: 6,
  302: 8,
  303: 6,
  304: 8,
  305: 6,
  306: 18,
  307: 12,
  308: 10,
  309: 7,
  310: 16,
  311: 11,
  312: 11,
  313: 13,
  314: 6,
  315: 13,
  316: 6,
  317: 13,
  318: 9,
  319: 13,
  320: 8,
  321: 13,
  322: 6,
  323: 14,
  324: 11,
  325: 14,
  326: 11,
  327: 14,
  328: 11,
  329: 11,
  330: 14,
  331: 11,
  332: 16,
  333: 10,
  334: 16,
  335: 10,
  336: 16,
  337: 10,
  338: 20,
  339: 14,
  340: 14,
  341: 9,
  342: 14,
  343: 9,
  344: 14,
  345: 9,
  346: 11,
  347: 8,
  348: 11,
  349: 8,
  350: 11,
  351: 8,
  352: 11,
  353: 8,
  354: 13,
  355: 7,
  356: 13,
  357: 11,
  358: 13,
  359: 7,
  360: 14,
  361: 11,
  362: 14,
  363: 11,
  364: 14,
  365: 11,
  366: 14,
  367: 11,
  368: 14,
  369: 11,
  370: 14,
  371: 11,
  372: 20,
  373: 14,
  374: 14,
  375: 10,
  376: 14,
  377: 13,
  378: 9,
  379: 13,
  380: 9,
  381: 13,
  382: 9,
  383: 7,
  384: 14,
  385: 15,
  386: 12,
  387: 13,
  388: 15,
  389: 13,
  390: 14,
  391: 14,
  392: 11,
  393: 16,
  394: 18,
  395: 12,
  396: 13,
  397: 12,
  398: 13,
  399: 13,
}

export const fontWidthTeaser = {
  0: 0,
  1: 5,
  2: 5,
  3: 5,
  4: 5,
  5: 5,
  6: 5,
  7: 5,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
  13: 0,
  14: 5,
  15: 5,
  16: 5,
  17: 5,
  18: 5,
  19: 5,
  20: 5,
  21: 5,
  22: 5,
  23: 5,
  24: 5,
  25: 5,
  26: 5,
  27: 5,
  28: 5,
  29: 0,
  30: 5,
  31: 5,
  32: 0,
  33: 5,
  34: 7,
  35: 8,
  36: 8,
  37: 13,
  38: 12,
  39: 3,
  40: 5,
  41: 5,
  42: 8,
  43: 9,
  44: 4,
  45: 5,
  46: 4,
  47: 4,
  48: 8,
  49: 8,
  50: 8,
  51: 8,
  52: 8,
  53: 8,
  54: 8,
  55: 8,
  56: 8,
  57: 8,
  58: 4,
  59: 4,
  60: 9,
  61: 9,
  62: 9,
  63: 7,
  64: 15,
  65: 12,
  66: 11,
  67: 11,
  68: 12,
  69: 10,
  70: 9,
  71: 12,
  72: 12,
  73: 5,
  74: 6,
  75: 12,
  76: 10,
  77: 14,
  78: 12,
  79: 12,
  80: 9,
  81: 12,
  82: 11,
  83: 9,
  84: 10,
  85: 12,
  86: 12,
  87: 15,
  88: 12,
  89: 12,
  90: 10,
  91: 5,
  92: 4,
  93: 5,
  94: 8,
  95: 8,
  96: 5,
  97: 7,
  98: 8,
  99: 7,
  100: 8,
  101: 7,
  102: 5,
  103: 8,
  104: 8,
  105: 4,
  106: 4,
  107: 8,
  108: 4,
  109: 12,
  110: 8,
  111: 8,
  112: 8,
  113: 8,
  114: 5,
  115: 6,
  116: 4,
  117: 8,
  118: 8,
  119: 12,
  120: 8,
  121: 8,
  122: 7,
  123: 8,
  124: 3,
  125: 8,
  126: 9,
  127: 8,
  128: 9,
  129: 16,
  130: 16,
  131: 16,
  132: 16,
  133: 16,
  134: 16,
  135: 16,
  136: 16,
  137: 16,
  138: 16,
  139: 16,
  140: 16,
  141: 16,
  142: 16,
  143: 16,
  144: 16,
  145: 16,
  146: 16,
  147: 16,
  148: 16,
  149: 16,
  150: 16,
  151: 16,
  152: 16,
  153: 16,
  154: 16,
  155: 16,
  156: 16,
  157: 16,
  158: 16,
  159: 16,
  160: 4,
  161: 5,
  162: 8,
  163: 8,
  164: 8,
  165: 8,
  166: 3,
  167: 8,
  168: 5,
  169: 12,
  170: 4,
  171: 8,
  172: 9,
  173: 0,
  174: 12,
  175: 5,
  176: 6,
  177: 9,
  178: 5,
  179: 5,
  180: 5,
  181: 9,
  182: 7,
  183: 4,
  184: 5,
  185: 5,
  186: 5,
  187: 8,
  188: 12,
  189: 12,
  190: 12,
  191: 7,
  192: 12,
  193: 12,
  194: 12,
  195: 12,
  196: 12,
  197: 12,
  198: 14,
  199: 11,
  200: 10,
  201: 10,
  202: 10,
  203: 10,
  204: 5,
  205: 5,
  206: 5,
  207: 5,
  208: 12,
  209: 12,
  210: 12,
  211: 12,
  212: 12,
  213: 12,
  214: 12,
  215: 9,
  216: 12,
  217: 12,
  218: 12,
  219: 12,
  220: 12,
  221: 12,
  222: 9,
  223: 8,
  224: 7,
  225: 7,
  226: 7,
  227: 7,
  228: 7,
  229: 7,
  230: 11,
  231: 7,
  232: 7,
  233: 7,
  234: 7,
  235: 7,
  236: 4,
  237: 4,
  238: 4,
  239: 4,
  240: 8,
  241: 8,
  242: 8,
  243: 8,
  244: 8,
  245: 8,
  246: 8,
  247: 9,
  248: 8,
  249: 8,
  250: 8,
  251: 8,
  252: 8,
  253: 8,
  254: 8,
  255: 8,
  256: 12,
  257: 7,
  258: 12,
  259: 7,
  260: 12,
  261: 7,
  262: 11,
  263: 7,
  264: 11,
  265: 7,
  266: 11,
  267: 7,
  268: 11,
  269: 7,
  270: 12,
  271: 10,
  272: 12,
  273: 8,
  274: 10,
  275: 7,
  276: 10,
  277: 7,
  278: 10,
  279: 7,
  280: 10,
  281: 7,
  282: 10,
  283: 7,
  284: 12,
  285: 8,
  286: 12,
  287: 8,
  288: 12,
  289: 8,
  290: 12,
  291: 8,
  292: 12,
  293: 8,
  294: 12,
  295: 8,
  296: 5,
  297: 4,
  298: 5,
  299: 4,
  300: 5,
  301: 4,
  302: 5,
  303: 4,
  304: 5,
  305: 4,
  306: 12,
  307: 9,
  308: 6,
  309: 4,
  310: 12,
  311: 8,
  312: 8,
  313: 10,
  314: 4,
  315: 10,
  316: 4,
  317: 10,
  318: 6,
  319: 10,
  320: 6,
  321: 10,
  322: 4,
  323: 12,
  324: 8,
  325: 12,
  326: 8,
  327: 12,
  328: 8,
  329: 8,
  330: 12,
  331: 8,
  332: 12,
  333: 8,
  334: 12,
  335: 8,
  336: 12,
  337: 8,
  338: 14,
  339: 12,
  340: 11,
  341: 5,
  342: 11,
  343: 5,
  344: 11,
  345: 5,
  346: 9,
  347: 6,
  348: 9,
  349: 6,
  350: 9,
  351: 6,
  352: 9,
  353: 6,
  354: 10,
  355: 4,
  356: 10,
  357: 7,
  358: 10,
  359: 4,
  360: 12,
  361: 8,
  362: 12,
  363: 8,
  364: 12,
  365: 8,
  366: 12,
  367: 8,
  368: 12,
  369: 8,
  370: 12,
  371: 8,
  372: 15,
  373: 12,
  374: 12,
  375: 8,
  376: 12,
  377: 10,
  378: 7,
  379: 10,
  380: 7,
  381: 10,
  382: 7,
  383: 5,
  384: 10,
  385: 11,
  386: 9,
  387: 10,
  388: 11,
  389: 9,
  390: 11,
  391: 11,
  392: 9,
  393: 12,
  394: 14,
  395: 9,
  396: 10,
  397: 9,
  398: 10,
  399: 11,
}
