/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import PageWidth from "../page-width"
import NewsCard from "../news-card"

import {Theme} from "../../styles/theme"

interface Props {
  limit?: number
}

const NewsBlock = (props: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      pages: allDatoCmsNewsItem {
        edges {
          node {
            id
            date
            title
            teaser
            slug
          }
        }
      }
    }
  `)

  let sortedNewsItems = data.pages.edges.sort(
    (a, b) => Date.parse(b.node.date) - Date.parse(a.node.date),
  )

  if (props.limit) sortedNewsItems = sortedNewsItems.slice(0, props.limit)

  return (
    <div css={NewsBlockStyles}>
      <PageWidth>
        {props.limit && <h1>Actueel Nieuws</h1>}
        <div css={NewsBlockContainerStyles}>
          {sortedNewsItems.map(item => (
            <NewsCard {...item.node} key={item.node.id} showTeaser={true} />
          ))}
        </div>

        {props.limit && data.pages.edges.length > props.limit && (
          <a href="/nieuws" css={ArchiveLinkStyles}>
            Oudere berichten staan in het archief{" "}
            <FontAwesomeIcon icon={["far", "arrow-right"]} />
          </a>
        )}
      </PageWidth>
    </div>
  )
}

export default NewsBlock

const NewsBlockStyles = () => css`
  margin-bottom: 10rem;
`

const NewsBlockContainerStyles = () => css`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -3rem 0 0 -3rem;
  width: calc(100% + 3rem);

  > * {
    margin: 3rem 0 0 3rem;
  }
`

const ArchiveLinkStyles = () => css`
  display: inline-block;
  margin-top: 4rem;
  color: ${Theme.colors.fontcolors.heading};
  font-weight: 400;

  svg {
    margin-left: 1.5rem;
  }
`
