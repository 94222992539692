/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {KeyboardEvent, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import {Theme} from "../styles/theme"
import {ButtonStyles} from "../styles/button-styles"

interface Props {
  handleSearch: (query: string) => void
}

const SearchBar = (props: Props): JSX.Element => {
  const [query, setQuery] = useState("")

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.handleSearch(query)
    }
  }

  return (
    <div css={SearchBarStyles}>
      <FontAwesomeIcon icon={["far", "search"]} />
      <input
        placeholder="Zoeken"
        value={query}
        onChange={e => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <a
        css={ButtonStyles({size: "small"})}
        onClick={() => props.handleSearch(query)}
      >
        <span className="mobile">
          <FontAwesomeIcon icon={["far", "search"]} />
        </span>
        <span className="desktop">Zoeken</span>
      </a>
    </div>
  )
}

export default SearchBar

export const SearchBarStyles = css`
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 3rem;
  z-index: 10;

  > svg {
    position: absolute;
    top: 4.2rem;
    left: 6rem;
    font-size: 2.2rem;
    color: ${Theme.colors.fontcolors.light};
  }

  input {
    padding: 0 15rem 0 8rem;
    margin: 1.8rem 0 2.3rem;
    width: 100%;
    border-radius: 0.2rem;
    height: 7.2rem;
    border: none;
    box-shadow: 0 0 2rem rgba(106, 106, 106, 0.3);
    -webkit-appearance: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #070707 !important;
    }

    &:hover,
    &:focus {
      outline: 0;
    }
  }

  a,
  button {
    position: absolute;
    right: 5rem;
    top: 3.2rem;
    line-height: 2rem;
    border-radius: 0.2rem;

    .mobile {
      display: none;
    }
  }

  @media ${Theme.breakpoints.mediumDown} {
    > svg {
      display: none;
    }

    input {
      padding: 0 8rem 0 2rem;
    }

    a {
      padding: 1.2rem 0.5rem 1.2rem 1.5rem;
    }

    a,
    button {
      span.mobile {
        display: inline;
      }

      span.desktop {
        display: none;
      }
    }
  }
`
