/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useEffect, useState} from "react"
import {navigate} from "gatsby"

import {Banner} from "../../types"

import SearchBar from "./search-bar"
import SingleBanner from "./single-banner"

import {Theme} from "../styles/theme"

interface Props {
  banners: Banner[]
  size?: "small" | "large"
}

const BannerGroup = (props: Props): JSX.Element => {
  const [currentBanner, setCurrentBanner] = useState(0)

  const handleSearch = (query: string) => {
    navigate(`/search?q=${query}`)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner(i => {
        let number = i + 1
        if (number >= props.banners.length) {
          number = 0
        }

        return number
      })
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      css={BannerGroupStyles}
      className={props.banners.length > 1 ? "carousel" : ""}
    >
      {props.banners.map((banner, i) => (
        <SingleBanner
          className={
            i != currentBanner && props.banners.length > 1 ? "hidden" : "active"
          }
          key={banner.bannerTitle}
          {...banner}
          index={i}
          size={props.size || "small"}
        />
      ))}
      <div className="searchbar-container">
        <SearchBar handleSearch={handleSearch} />
      </div>
    </div>
  )
}

export default BannerGroup

const BannerGroupStyles = () => css`
  position: relative;
  margin-bottom: 10rem;
  &.carousel {
    @media ${Theme.breakpoints.medium} {
      min-height: 60rem;

      > .active,
      > .hidden {
        z-index: 0;
        position: absolute;

        .page-width {
          opacity: 0;
          transition: opacity 1s ease-in-out;
        }

        &.active {
          .page-width {
            opacity: 1;
          }
        }
        &.hidden {
          pointer-events: none;
        }
      }
    }
  }

  .searchbar-container {
    position: absolute;
    width: 100%;
    bottom: -6rem;
  }
`
