/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import "dayjs/locale/nl"

import {NewsItem} from "../../types"

import {Theme} from "../styles/theme"

import {divideTextInLines} from "../transformers"
import {fontWidthH4, fontWidthTeaser} from "../styles/font-width"

interface Props extends NewsItem {
  showTeaser?: boolean
}

const NewsCard = (props: Props): JSX.Element => {
  const cardWidth = 270
  const cardHeight = 236
  const descriptionLineHeight = 23

  const titleLines = divideTextInLines(cardWidth - 10, fontWidthH4, props.title)

  const amountOfLines = Math.floor(
    (cardHeight - (titleLines.length * 26 + 13)) / descriptionLineHeight,
  )

  const lines = divideTextInLines(
    cardWidth,
    fontWidthTeaser,
    props.teaser,
    amountOfLines,
  )

  dayjs.locale("nl")

  return (
    <a href={`/nieuws/${props.slug}`} css={NewsCardStyles}>
      <div className="date">
        {dayjs(new Date(props.date)).format("D MMMM YYYY").toLowerCase()}
      </div>

      <div className="content">
        <h4>{props.title}</h4>
        {props.showTeaser && (
          <p>
            {lines.map((line, i) => (
              <span key={i} className={i + 1 == lines.length ? "last" : ""}>
                {line}
                <br />
              </span>
            ))}
          </p>
        )}
      </div>
      <FontAwesomeIcon icon={["far", "arrow-right"]} />
    </a>
  )
}

export default NewsCard

const NewsCardStyles = () => css`
  flex-grow: 1;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 35rem; // IE11
  max-width: min(90vw, 35rem);
  height: 35rem;
  color: unset;
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem rgba(106, 106, 106, 0.3);
  &:hover {
    text-decoration: none;

    h4 {
      text-decoration: underline;
    }
  }

  .date,
  .content,
  svg {
    max-width: 27rem;
  }

  .date {
    font-size: 1.4rem;
    font-weight: 700;
    color: ${Theme.colors.identity.primary};
  }

  .content {
    flex-grow: 1;
    overflow: hidden;

    h4 {
      margin: 0.5rem 0 0.8rem;
    }

    p {
      margin: 0;
      color: #666666;
      font-size: 1.6rem;
      line-height: 2.3rem;
      font-weight: normal;

      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        &.last {
          text-overflow: ellipsis;
        }
      }
    }
  }

  svg {
    margin-top: 1.1rem;
    color: ${Theme.colors.identity.primary};
  }

  @media ${Theme.breakpoints.mobileDown} {
    height: unset;

    .content {
      p {
        display: none;
      }
    }
  }
`
